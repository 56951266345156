import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectFormState, selectOrderState } from "../../slices/formSlice";
import { scrollIntoErrorView } from "../helpers";
import { ParentBlockValidationType } from "../types";
import {
  FulfillmentMethodEnum,
  OrderTableHeaderSortParamEnum,
  StaticSuperBlockKeyEnum,
} from "../enums";

interface IErrorProps {
  validations: ParentBlockValidationType[];
  message?: string;
  parentBlockId: string;
}

const Errors: React.FC<IErrorProps> = (props) => {
  const formState = useSelector(selectFormState);
  const orderState = useSelector(selectOrderState);
  /**
   * @dev NOTE: pseudoParentBlock should only be used when there is no parent block, but the same functionality is required. For example, static blocks.
   */
  const isPseudoParentBlock =
    props.parentBlockId.includes(FulfillmentMethodEnum.DELIVERY) ||
    props.parentBlockId.includes(FulfillmentMethodEnum.PICKUP) ||
    props.parentBlockId.includes(
      OrderTableHeaderSortParamEnum.FULFILLMENT_METHOD
    ) ||
    props.parentBlockId.includes(StaticSuperBlockKeyEnum.CONTACT_INFO);
  const shouldScrollToParentBlockErrorsFirst =
    formState.parentBlockErrors?.length !== 0 &&
    formState.parentBlockErrors?.length >= formState.allStaticErrors?.length;

  useEffect(() => {
    if (isPseudoParentBlock && shouldScrollToParentBlockErrorsFirst) {
      return;
    } else {
      scrollIntoErrorView();
    }
  }, [orderState.payButtonClickCount, props.message, props.parentBlockId]);

  return (
    <p className="form-error-message">
      {/**
       * @dev only display one error message at a time
       **/}
      {props.message}

      {/**
       * @dev this is useful for debugging in development
       */}
      {process.env.NODE_ENV === "development" &&
        JSON.stringify(props.validations)}
    </p>
  );
};

export default Errors;
