import { PaymentTypeKeyEnum } from "../components/enums";
import { FORMPAY_API_BASE_URL } from "../constants";
import { fetchRequest } from "./helpers";
import { defaultPostOptions } from "./requestOptions";

export class OrderService {
  #endpoint = `${FORMPAY_API_BASE_URL}/orders`;
  #userToken: string | null;

  constructor(userToken: string | null) {
    this.#userToken = userToken;
  }

  async createOrder(payload: {
    orderDetails: object;
    paymentTypeKey: PaymentTypeKeyEnum;
  }) {
    try {
      const fetchOptions = {
        ...defaultPostOptions,
        headers: {
          ...defaultPostOptions.headers,
          Authorization: `Bearer ${this.#userToken}`,
        },
        body: JSON.stringify(payload.orderDetails),
      };
      const response = await fetchRequest(
        this.generateCreateEndpoint(payload.paymentTypeKey),
        fetchOptions
      );

      return response;
    } catch (e) {
      if (e instanceof Error) {
        throw new Error(`Error creating order: ${e.message}`);
      }
    }
  }

  generateCreateEndpoint(paymentType: PaymentTypeKeyEnum) {
    return `${this.#endpoint}?payment_type=${paymentType}`;
  }
}
