const _defaultRequestOptions = {
  credentials: "include",
  headers: {
    "Content-Type": "application/json;charset=utf-8",
  },
};

export const defaultGetOptions = {
  ..._defaultRequestOptions,
  method: "GET",
};

export const defaultPostOptions = {
  ..._defaultRequestOptions,
  method: "POST",
};

export const defaultPutOptions = {
  ..._defaultRequestOptions,
  method: "PUT",
};

export const defaultDeleteOptions = {
  ..._defaultRequestOptions,
  method: "DELETE",
};
