import { STRIPE_CONNECT_DASHBOARD_URL } from "../constants";
import { OrderService } from "../services/orderService";
import { IFormState } from "../slices/formSlice";
import { AppDispatch } from "../store";
import {
  OrderStatusEnum,
  OrderStatusKeyEnum,
  PaymentStatusEnum,
  PaymentStatusKeyEnum,
} from "./enums";
import { IForm } from "./interfaces";
import {
  CheckboxRequirementsType,
  DropdownOptionValueType,
  ParentBlockValidationType,
  PaymentTypeKeyType,
} from "./types";

/**
 * @description converts validations string array (TS type: string[]) into a JSON string
 */
const createDataValidations = (validations: ParentBlockValidationType[]) => {
  return JSON.stringify(validations);
};

const generateCharCountRequirements = (
  inputCharCount: number,
  maxCharCount: number
) => `(${inputCharCount}/${maxCharCount} characters)`;

type FormatPriceOptions = {
  wrapInBrackets?: boolean;
  alwaysDisplayCents?: boolean;
  isNegative?: boolean;
};

const formatPrice = (price: number, options: FormatPriceOptions = {}) => {
  const isInvalidPrice = price === null || price === undefined;
  if (isInvalidPrice) return "";

  const currencySymbol = "$";
  const separator = ".";
  let formattedPrice = (price / 100).toString();

  /**
   * @description float number as opposed to integer
   */
  const isFloat = formattedPrice.includes(separator);

  if (options.alwaysDisplayCents && !isFloat) {
    formattedPrice += ".00";
  }

  if (isFloat) {
    const [priceInDollars, priceInCents] = (price / 100)
      .toFixed(2)
      .split(separator);
    formattedPrice = currencySymbol + priceInDollars + separator + priceInCents; // i.e. $100.10 or $100.11 or $1.00
  } else {
    formattedPrice = currencySymbol + formattedPrice; // i.e. $100
  }

  if (options.isNegative) {
    formattedPrice = `-${formattedPrice}`; // i.e. -$100.00
  }

  if (options.wrapInBrackets) {
    // i.e. ($100) or (-$100) when isNegative is true
    return `(${formattedPrice})`;
  }

  return formattedPrice;
};

type ItemWithPrice = {
  parentBlockLabel: string;
  value: string;
  price: number;
};

type QuantityDropdownType = {
  [key: string]: DropdownOptionValueType;
};

type FulfillmentFeeType = {
  fulfillment_fee: number;
};
const _calculateSubtotalFromState = (formState: IFormState, form?: IForm) => {
  const quantityDropdownItems: QuantityDropdownType[] = [];
  /**
   * @dev NOTE: nested looping should be temporary, until Carts and CartItems are implemented.
   *
   * Until then, we can extract nested dropdown values with this function.
   */
  const _extractQuantityDropdownItems = (
    itemWithPrice: QuantityDropdownType
  ) => {
    const cbItems = Object.entries(itemWithPrice).filter(
      (parentBlockKeyValuePair) => {
        if (!parentBlockKeyValuePair[1]) {
          return false;
        } else {
          return Object.prototype.hasOwnProperty.call(
            parentBlockKeyValuePair[1],
            "price"
          );
        }
      }
    );

    if (cbItems.length > 0) {
      const dropdownItem: QuantityDropdownType = {};

      cbItems.forEach((arr) => {
        dropdownItem[arr[0]] = arr[1];
      });

      if (Object.values(dropdownItem).length > 0) {
        quantityDropdownItems.push(dropdownItem);
      }
    }
  };

  let deliveryFee = 0;
  const itemsWithPrice: ItemWithPrice[] = Object.values(formState).filter(
    (item: QuantityDropdownType | FulfillmentFeeType | null) => {
      if (item) {
        // TODO: remove type casting and fix 'item' type
        _extractQuantityDropdownItems(item as QuantityDropdownType);
      }
      if (!item) return false;

      if (Object.prototype.hasOwnProperty.call(item, "fulfillment_fee")) {
        if (item["fulfillment_fee"]) {
          // TODO: remove type casting and fix 'item' type
          deliveryFee = (item as FulfillmentFeeType)["fulfillment_fee"];
        }
      } else {
        return Object.prototype.hasOwnProperty.call(item, "price");
      }
    }
  );

  if (quantityDropdownItems.length > 0) {
    Object.values(quantityDropdownItems).forEach((nestedDropdown) => {
      // TODO: consider if there are any other more performant alternatives instead of double nested for loop
      Object.values(nestedDropdown).forEach((item) => {
        if (!item.quantity) return null;
        let price = item.price;

        /**
         * @dev
         * - If the `defaultValue` is selected, quantity should be `null`.
         * - All other scenarios should have a quantity and should never be `undefined`.
         */
        if (!!item.quantity) {
          price = price * item.quantity;
        }

        itemsWithPrice.push({
          parentBlockLabel: item.value,
          value: "",
          price,
        });
      });
    });
  }

  const itemPrices: number[] = itemsWithPrice.map((item) => item.price);
  const subtotal = itemPrices.reduce((total, price: number) => {
    return (total += price);
  }, 0);

  const discount = formState["promoCode"]?.["discountAmount"] || 0;
  const minimumSpendRequirement =
    form?.fulfillment_setting?.min_delivery_amount || 0;

  const qualifiesForWaivedDelivery = minimumSpendRequirement
    ? subtotal - discount >= minimumSpendRequirement
    : false;

  const subTotalAfterDiscount = qualifiesForWaivedDelivery
    ? subtotal - discount
    : subtotal - discount + deliveryFee;

  const isSubtotalValid = subTotalAfterDiscount > 0;

  return isSubtotalValid ? subTotalAfterDiscount : 0;
};

// /**
//  * @description Rounds up the thousandth value if it is 5 or higher, otherwise rounds down. Rounding will result in a precision of 2 decimal places.
//  *
//  * @param fullDollarAmount The full dollar amount to round (i.e. $100.104)
//  * @param separator The decimal place separator
//  */
// const roundCents = (fullDollarAmount: number, separator: string) => {
//   const precision = Math.pow(10, 2);
//   const formattedCents = fullDollarAmount.toString().split(separator)[1];
//   let amountOfCents;

//   if (!formattedCents) {
//     /**
//      * @dev i.e. If a price is $75, the formattedCents will be undefined
//      */
//     amountOfCents = "00";
//     return amountOfCents;
//   }

//   if (formattedCents.length === 2) {
//     /**
//      * @dev i.e. If a price is $75.55, it already has the proper amount of cents and should not be rounded up or down
//      */
//     return formattedCents;
//   } else if (formattedCents.length < 2) {
//     /**
//      * @dev i.e. If a price is $75.1, the formattedCents will have a length of 1
//      */
//     amountOfCents = fullDollarAmount.toString().split(separator)[1];
//   } else {
//     const thousandthsDecimalPlaceValue = formattedCents[2];
//     if (Number(thousandthsDecimalPlaceValue) <= 4) {
//       const roundedDownFullAmount =
//         Math.floor(fullDollarAmount * precision) / precision;
//       amountOfCents = roundedDownFullAmount.toString().split(separator)[1];
//     } else {
//       const roundedUpFullAmount =
//         Math.ceil(fullDollarAmount * precision) / precision;

//       // TODO: when value is 0.9975 it gets rounded to 1
//       // However because this is rounding cents, it gets used by appending this 1 value to the total price in dollars.
//       // This value should be added to the total dollars price instead of appended as cents.
//       if (roundedUpFullAmount.toString().includes(separator)) {
//         amountOfCents = roundedUpFullAmount.toString().split(separator)[1];
//       } else {
//         amountOfCents = roundedUpFullAmount.toString();
//       }
//     }
//   }
//   return addTrailingZeroToAmount(amountOfCents);
// };

// const addTrailingZeroToAmount = (amount: string) => {
//   if (amount.length === 1) {
//     return amount + "0";
//   }

//   return amount;
// };

const getSubtotalDisplayValueFromState = (
  formState: IFormState,
  form: IForm
) => {
  const subtotal = _calculateSubtotalFromState(formState, form);
  return subtotal;
};

const getTaxesFromState: (formState: IFormState, form: IForm) => number = (
  formState: IFormState,
  form: IForm
) => {
  if (!form.tax_percentage) return 0;
  return _calculateSubtotalFromState(formState, form) * form.tax_percentage;
};

export type StateSetterType = {
  setOrderErrorMessage: (errorMessage: string) => void;
  dispatch: AppDispatch;
  setFormIsSubmitting: (isSubmitting: boolean) => void;
};
const handleCreateOrder = async (
  orderDetails: object, // TODO: fix type - or wait until Carts and OrderItems are separated concerns
  { setOrderErrorMessage, dispatch, setFormIsSubmitting }: StateSetterType,
  paymentTypeKey: PaymentTypeKeyType,
  userToken: string
) => {
  // TODO: consider using React Query mutation to manage this POST request
  try {
    dispatch(setFormIsSubmitting(true));
    dispatch(setOrderErrorMessage(""));

    const payload = {
      orderDetails,
      paymentTypeKey,
    };
    const orderService = new OrderService(userToken);
    const result = await orderService.createOrder(payload);

    if (result.errors) {
      return { errors: result.errors };
    }

    const { payment_url, cash_payment } = result;

    if (payment_url) {
      window.location.replace(payment_url);
    } else {
      if (!cash_payment) {
        throw new Error(
          "Unable to generate payment for order. Please reach out to the merchant for more details."
        );
      }
    }
  } catch (error) {
    if (error instanceof Error) {
      dispatch(setOrderErrorMessage(error.message));
    }
  }
  return { errors: null };
};

/**
 * @description scrolls into view of the first parent block with error that exists, unless the parent block's section is collapsed.
 *
 * (If the section block is collapsed, it will scroll to the section block itself since it should have errors and requires the user to uncollapse it to view the first parent block with errors.)
 */
const scrollIntoErrorView = () => {
  const parentBlockNodesWithErrors = document.querySelectorAll(
    ".form-error-message"
  ) as unknown as HTMLElement[];
  const sectionBlockNodesWithErrors = document.querySelectorAll(
    ".section-heading-container__error.collapsed"
  ) as unknown as HTMLElement[];

  const sectionBlockErrors = Array.from(sectionBlockNodesWithErrors);
  const parentBlockErrors = Array.from(parentBlockNodesWithErrors);

  /**
   * @dev NOTE: if offsetTop === 0, then the HTMLElement is not visible on the screen.
   * This can occur in 2 scenarios:
   * 1. ParentBlock is not visible because it is in a collapsed section.
   * 2. SectionBlock is not visible because it is conditionally rendered as a static super block.
   */
  const firstParentBlockError = parentBlockErrors.find(
    (error) => error.offsetTop > 0
  );
  const firstCollapsedSectionWithError = sectionBlockErrors.find(
    (error) => error.offsetTop > 0
  );

  if (firstParentBlockError) {
    const firstParentBlockWithErrors = firstParentBlockError.parentElement;

    if (firstCollapsedSectionWithError && firstParentBlockError) {
      if (firstCollapsedSectionWithError.offsetTop !== 0) {
        if (
          firstParentBlockError.offsetTop >
          firstCollapsedSectionWithError.offsetTop
        ) {
          // first pb error exists in first collapsed section, so scroll to the first collapsed section.
          return firstCollapsedSectionWithError.scrollIntoView({
            block: "start",
            behavior: "smooth",
          });
        } else {
          // first pb error exists in a section that is not collapsed, but other subsequent sections are collapsed.
          return firstParentBlockWithErrors?.scrollIntoView({
            block: "start",
            behavior: "smooth",
          });
        }
      }
    } else {
      // No sections are collapsed, so scroll to the first pb error.
      return firstParentBlockWithErrors?.scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    }
  }

  if (firstCollapsedSectionWithError) {
    // All sections with errors are collapsed, so scroll to the first collapsed section.
    return firstCollapsedSectionWithError.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  }

  // No errors exist, so no need to scroll anywhere.
  return;
};

const mapOrderStatusLabelToAttributeName = (
  orderStatus: OrderStatusEnum | PaymentStatusEnum
) => {
  switch (orderStatus) {
    /**
     * @dev NOTE: fulfillment statuses go here
     */
    case OrderStatusEnum.Open:
      return OrderStatusKeyEnum.Open;
    case OrderStatusEnum.InProgress:
      return OrderStatusKeyEnum.InProgress;
    case OrderStatusEnum.Complete:
      /**
       * @dev Backend uses "delivered" instead of "complete" - consider normalizing these values
       */
      return OrderStatusKeyEnum.Complete;
    case OrderStatusEnum.Cancelled:
      return OrderStatusKeyEnum.Cancelled;
    /**
     * @dev NOTE: payment statuses go here
     */
    case PaymentStatusEnum.Unpaid:
      return PaymentStatusKeyEnum.Unpaid;
    case PaymentStatusEnum.PaymentPending:
      return PaymentStatusKeyEnum.PaymentPending;
    case PaymentStatusEnum.Paid:
      return PaymentStatusKeyEnum.Paid;
    case PaymentStatusEnum.Refunded:
      return PaymentStatusKeyEnum.Refunded;
  }
};

const generateStripePaymentUrl = (
  accountId: string,
  paymentIntentId: string | null
): string | null => {
  if (!(accountId && paymentIntentId)) return null;
  const url = `${STRIPE_CONNECT_DASHBOARD_URL}${accountId}/payments/${paymentIntentId}`;

  return url;
};

const checkBoxSelectionHelpText = ({
  exactAmount,
  minAmount,
  maxAmount,
}: CheckboxRequirementsType) => {
  let helpText = "";

  if (exactAmount) {
    helpText = `Choose ${exactAmount} options:`;
  } else if (minAmount && maxAmount) {
    helpText = `Choose ${minAmount} to ${maxAmount} options:`;
  } else if (minAmount) {
    helpText = `Choose at least ${minAmount} options:`;
  } else if (maxAmount) {
    helpText = `Choose up to ${maxAmount} options:`;
  }

  return helpText;
};

type LogOptionsType = {
  label?: string;
};
const logDevOutputToConsole = (
  payload: object,
  options: LogOptionsType = {}
) => {
  if (process.env.NODE_ENV !== "development") return;

  return !options.label
    ? console.log(options.label, { devLog: payload })
    : console.log({ devLog: payload });
};

export {
  checkBoxSelectionHelpText,
  createDataValidations,
  formatPrice,
  generateCharCountRequirements,
  generateStripePaymentUrl,
  getSubtotalDisplayValueFromState,
  getTaxesFromState,
  handleCreateOrder,
  logDevOutputToConsole,
  mapOrderStatusLabelToAttributeName,
  scrollIntoErrorView,
};
