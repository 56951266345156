import { FetchError, UserUnauthorizedError } from "../errors";

export interface IErrorResponse {
  errors: {
    [key: string]: string[];
  };
}
type GenericResponseType = (
  endpoint: string,
  fetchOptions: object
) => any & IErrorResponse;

/**
 * @dev all uses of fetchRequest should implement try/catch blocks to catch the errors being thrown on the following HTTP status codes: [404, 403, 401, 422, 500]
 */
export const fetchRequest: GenericResponseType = async (
  endpoint: string,
  fetchOptions: object
) => {
  const response = await fetch(endpoint, fetchOptions);
  const result = await response.json();

  if (!response.ok) {
    switch (response.status) {
      case 401:
      case 403:
        throw new UserUnauthorizedError();
      case 422:
        return result;
      default:
        throw new FetchError(result.message);
    }
  }

  return result;
};
